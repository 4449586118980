import React, { useEffect, useMemo, useState } from 'react';
import { TestKitSummaryItem, TestKitSummaryStepStatus } from './test-kit-summary-item.component';
import { Button } from '@when-fertility/shared/domain/common';
import { ACTIVATE_ROUTES } from '../../activate';
import { QUIZ_ROUTES } from 'domain/quiz/quiz.routes';
import gql from 'graphql-tag';
import {
  GqlTestKitSummaryTestKitFragmentFragment,
  useTestKitSummaryGetTestKitsQuery,
  useTestKitSummaryGetPatientQuery,
} from '@when-fertility/shared/gql/graphql';
import { testKitService } from 'domain/test-kit';
import { CollectSampleButton, PrepareForResultsButton, ViewedResultButton, NextStepSection } from '.';
import { useCurrentUser, USER_ROUTES } from 'domain/user';
import { Link } from 'react-router-dom';
import { DownloadReportButton } from '@when-fertility/shared/domain/results';

export type TestKitSummaryStep = 'activate' | 'quiz' | 'collect' | 'prepare' | 'results' | 'next';

export const TestKitSummary = () => {
  const [expandedItem, setExpandedItem] = useState<TestKitSummaryStep | null>(null);
  const { loggedInUserId } = useCurrentUser();
  const { data: testKitData } = useTestKitSummaryGetTestKitsQuery({
    fetchPolicy: 'cache-and-network',
    skip: !loggedInUserId,
  });
  const { data: patientData } = useTestKitSummaryGetPatientQuery({
    fetchPolicy: 'cache-and-network',
    ...(loggedInUserId && { variables: { patientInput: { id: loggedInUserId } } }),
    skip: !loggedInUserId,
  });
  const latestTestKit = useMemo(
    () => testKitService.getLatestTestKit<GqlTestKitSummaryTestKitFragmentFragment>(testKitData?.testKits.testKits),
    [testKitData]
  );
  const latestTestKitResult = useMemo(() => {
    const testKit = testKitService.getLatestTestKit<GqlTestKitSummaryTestKitFragmentFragment>(testKitData?.testKits.testKits);
    return testKit?.results?.[0];
  }, [testKitData]);
  const isTestKitActivated = useMemo(() => testKitService.getIsTestKitActivated(latestTestKit?.status), [latestTestKit?.status]);
  const isSampleCollected = useMemo(() => Boolean(latestTestKit?.isSampleCollected), [latestTestKit]);
  const hasPreparedForResults = useMemo(() => Boolean(latestTestKit?.hasPreparedForResults), [latestTestKit]);
  const hasViewedResult = useMemo(() => Boolean(latestTestKit?.hasViewedResult), [latestTestKit]);
  const isProfileCompleted = useMemo(() => Boolean(patientData?.patientById.isProfileComplete), [patientData]);
  const isResultReady = useMemo(() => latestTestKit?.resultsStatus === 'RECEIVED_RELEASED', [patientData]);

  const itemStepMapping: Record<TestKitSummaryStep, TestKitSummaryStepStatus> = useMemo(() => {
    return {
      activate: isTestKitActivated ? 'completed' : 'currentStep',
      quiz: isProfileCompleted ? 'completed' : isTestKitActivated ? 'currentStep' : 'futureStep',
      collect: isProfileCompleted ? (isSampleCollected ? 'completed' : 'currentStep') : 'futureStep',
      prepare: isSampleCollected ? (hasPreparedForResults ? 'completed' : 'currentStep') : 'futureStep',
      results: latestTestKit && hasPreparedForResults ? (hasViewedResult ? 'completed' : 'currentStep') : 'futureStep',
      next: hasViewedResult ? 'completed' : 'futureStep',
    };
  }, [isTestKitActivated, isSampleCollected, latestTestKit?.resultsStatus, isProfileCompleted, hasPreparedForResults, hasViewedResult]);

  useEffect(() => {
    const steps: TestKitSummaryStep[] = ['activate', 'collect', 'quiz', 'prepare', 'results', 'next'];
    const currentStep = steps.find((step) => itemStepMapping[step] === 'currentStep');
    if (!currentStep) {
      // if next are completed, expand next section
      if (itemStepMapping.next === 'completed') {
        setExpandedItem('next');
        return;
      }
      return;
    }
    setExpandedItem(currentStep);
  }, [itemStepMapping]);

  return (
    <div className="bg-white rounded-2xl border border-silver-100">
      <TestKitSummaryItem
        title="Activate your kit"
        stepKey="activate"
        expandedItem={expandedItem}
        setExpandedItem={setExpandedItem}
        stepStatus={itemStepMapping['activate']}
      >
        {isTestKitActivated && <div>Great! Your kit has been activated.</div>}
        {!isTestKitActivated && (
          <>
            <div className="mb-6">
              You&apos;ll need to activate your kit using the code on your activation card before you can collect your sample.
            </div>
            <Button isLink href={ACTIVATE_ROUTES.root}>
              Activate kit
            </Button>
          </>
        )}
      </TestKitSummaryItem>
      <TestKitSummaryItem
        stepStatus={itemStepMapping['quiz']}
        title="Complete your profile"
        stepKey="quiz"
        expandedItem={expandedItem}
        setExpandedItem={setExpandedItem}
      >
        {patientData?.patientById.isProfileComplete && (
          <div>
            Your profile is complete! Update it any time
            <Link to={USER_ROUTES.profile} className="underline ml-1">
              here.
            </Link>
          </div>
        )}
        {!patientData?.patientById.isProfileComplete && (
          <>
            <div className="mb-6">
              Our medical team uses this information to make sure we deliver personalised insights to you about your egg count. You’ll need to
              complete your profile before we can make your report available.
            </div>
            <Button isLink className="text-xs" href={QUIZ_ROUTES.root}>
              Complete profile
            </Button>
          </>
        )}
      </TestKitSummaryItem>
      <TestKitSummaryItem
        stepStatus={itemStepMapping['collect']}
        title="Collect your sample"
        stepKey="collect"
        expandedItem={expandedItem}
        setExpandedItem={setExpandedItem}
      >
        {isSampleCollected && <div>You&apos;ve collected your sample. Please return your kit promptly. </div>}
        {isProfileCompleted && !isSampleCollected && (
          <>
            <div className="mb-6">Thanks for completing your profile, now it's time to collect your sample.</div>
            <div className="mb-3">
              Our Senior Fertility Nurse will show you how to collect your sample in the video below. Along with the instructions in your kit, this
              will make sure you collect your sample successfully.
            </div>
            <div className={'flex items-center mb-6'}>
              <div className={'w-full min-w-32'}>
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    src="https://player.vimeo.com/video/954657002?h=858d170620&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', borderRadius: '8px' }}
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
            <CollectSampleButton testKit={latestTestKit} isDisabled={!isTestKitActivated} />
          </>
        )}
        {!isTestKitActivated && <>Please activate your kit before collecting your sample.</>}
      </TestKitSummaryItem>
      <TestKitSummaryItem
        stepStatus={itemStepMapping['prepare']}
        title="Prepare for your results"
        stepKey="prepare"
        expandedItem={expandedItem}
        setExpandedItem={setExpandedItem}
      >
        {hasPreparedForResults && <div>You&apos;ve finished this step. </div>}
        {!hasPreparedForResults && (
          <>
            <div className="mb-3">
              While we process your sample and prepare your report, WHEN’s Chief Medical Advisors would like to share some important information with
              you about AMH and your overall fertility picture.
            </div>
            <div className={'flex items-center h-40 sm:h-72 lg:h-80 xl:h-96'}>
              <div className={'w-full min-w-32'}>
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    src="https://player.vimeo.com/video/954671874?h=429e6a6472&badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', borderRadius: '20px' }}
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
            <div className={'flex items-center h-40 sm:h-72 lg:h-80 xl:h-96'}>
              <div className={'w-full min-w-32'}>
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    src="https://player.vimeo.com/video/954672047?h=8a2ab2572f&badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', borderRadius: '20px' }}
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
            <PrepareForResultsButton testKit={latestTestKit} isDisabled={!isTestKitActivated} />
          </>
        )}
      </TestKitSummaryItem>
      <TestKitSummaryItem
        stepStatus={itemStepMapping['results']}
        title="Get your result"
        stepKey="results"
        expandedItem={expandedItem}
        setExpandedItem={setExpandedItem}
      >
        {!latestTestKit && <div className="mb-6">Once you&apos;ve activated and sent your testKit, we can start processing your results.</div>}
        {!isResultReady && <div className="mb-6">We&apos;re waiting on your results. We&apos;ll let you know as soon as they&apos;re ready!</div>}
        {isResultReady && isProfileCompleted && (
          <>
            <div className="mb-6">Your result is ready for review.</div>
            <DownloadReportButton size="regular" testKitId={latestTestKit?.id} reportType={'EGG_COUNT_REPORT'} />
            <div className="mb-3" />
            <div className="mb-3" />
            <ViewedResultButton testKit={latestTestKit} isDisabled={hasViewedResult} />
          </>
        )}
      </TestKitSummaryItem>
      <TestKitSummaryItem
        stepStatus={itemStepMapping['next']}
        title="Your next steps"
        stepKey="next"
        expandedItem={expandedItem}
        setExpandedItem={setExpandedItem}
      >
        <NextStepSection result={latestTestKitResult} testKit={latestTestKit} />
      </TestKitSummaryItem>
    </div>
  );
};

TestKitSummary.fragments = {
  testKits: gql`
    fragment TestKitSummaryTestKitFragment on TestKit {
      id
      status
      isSampleCollected
      dateSampleCollected
      resultsStatus
      hasPreparedForResults
      hasViewedResult
      apptBookedAnswer
      results {
        abbreviation
        units
        name
        result
        report {
          ageGroup
          notes
          referenceRange
          range {
            PERCENTILE_2_5
            LOW
            HIGH
          }
        }
      }
    }
  `,
  patient: gql`
    fragment TestKitSummaryPatientFragment on Patient {
      id
      isProfileComplete
    }
  `,
};

TestKitSummary.query = {
  testKits: gql`
    ${TestKitSummary.fragments.testKits}

    query TestKitSummaryGetTestKits($testKitInput: TestKitInput) {
      testKits(input: $testKitInput) {
        testKits {
          ...TestKitSummaryTestKitFragment
        }
      }
    }
  `,
  patient: gql`
    ${TestKitSummary.fragments.patient}

    query TestKitSummaryGetPatient($patientInput: ByIdInput) {
      patientById(input: $patientInput) {
        ...TestKitSummaryPatientFragment
      }
    }
  `,
};
